var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "건강검진결과 엑셀업로드" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "적용", icon: "save" },
                        on: { btnClicked: _vm.acceptData },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "병원",
                    name: "hospitalName",
                  },
                  model: {
                    value: _vm.popupParam.hospitalName,
                    callback: function ($$v) {
                      _vm.$set(_vm.popupParam, "hospitalName", $$v)
                    },
                    expression: "popupParam.hospitalName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "요청부서/이름/일자",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-check-up-excel-upload2", {
        attrs: {
          heaCheckupPlanId: _vm.popupParam.heaCheckupPlanId,
          editable: _vm.editable,
          hospitalId: _vm.popupParam.hospitalId,
          excelUploadInfo: _vm.excelUploadInfo,
          isCustomDown: true,
          tabHeight: "660px",
          gridHeight: "630px",
        },
        on: { noUpload: _vm.noUpload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }